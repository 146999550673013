<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-lg">

                    <h3>{{ title }}</h3>
                </div>
                <div class="col-lg-auto" v-if="!makeReadOnly">
                    <button class="btn btn-outline-secondary"
                            v-on:click="addAdditionalItem()"
                            type="button">Dodaj
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="form-group  ">

                <div v-for="(newField,index) in newAdditionalFields">
                    <div class="row">

                        <div class="col-lg-6">
                            <label class="w-100">
                                Nazwa
                                <v-select :value="newField.id"
                                          v-model="newField.additional_field"
                                          :clearable="false"
                                          @input="(country) => myChangeEvent(newField, country)"
                                          :options="additionalFields"
                                          label="name">
                                    <template #search="{attributes, events}">
                                        <input
                                            class="vs__search"
                                            :required="!newField.additional_field"
                                            v-bind="attributes"
                                            v-on="events"
                                        />
                                    </template>

                                </v-select>

                            </label>
                            <input type="hidden"
                                   v-bind:name="'additional_field['+index+'][id]'"
                                   @change="emitChange"
                                   v-bind:value="newField.additional_field?.id"
                            >
                        </div>
                        <div class="col-lg-6">
                            <label v-if="newField.additional_field?.type==='date'"
                                   class="w-100">
                                Data
                                <input type="date"
                                       v-bind:name="'additional_field['+index+'][param1]'"
                                       class="form-control"
                                       required
                                       @change="emitChange"
                                       v-model="newField.param1"/>

                            </label>


                            <div v-if="newField.additional_field?.type==='range'" class="row">
                                <label class="col-lg-6">
                                    Data od
                                    <input type="date"
                                           v-bind:name="'additional_field['+index+'][param1]'"
                                           class="form-control"
                                           required
                                           @change="emitChange"
                                           v-model="newField.param1"/>

                                </label>
                                <label class="col-lg-6">
                                    Data do
                                    <input type="date"
                                           v-bind:name="'additional_field['+index+'][param2]'"
                                           class="form-control"
                                           required
                                           @change="emitChange"
                                           v-model="newField.param2"/>

                                </label>
                            </div>

                        </div>

                        <div class="col-lg-12">


                            <input type="hidden"
                                   v-if="newField.additional_field?.type!=='date'"
                                   v-bind:name="'additional_field['+index+'][name]'"
                                   @change="emitChange"
                                   value="">
                            <span v-if="!newField.additional_field_id">
                            <strong>Stara nazwa</strong><br>
                            {{ newField.name }}
                        </span>
                        </div>


                        <div class="col-lg-6" v-if="hasActivation">
                            <label class="w-100">
                                Aktywny od
                                <input type="date"
                                       v-bind:name="'additional_field['+index+'][activated_at]'"
                                       class="form-control"
                                       @change="emitChange"
                                       v-model="newField.activated_at"/>

                            </label>
                        </div>
                        <div class="col-lg">
                            <label class="">
                                Wartość
                                <input type="number"
                                       step="0.01"
                                       class="form-control"
                                       @change="emitChange"
                                       v-bind:name="'additional_field['+index+'][value]'"
                                       v-model="newField.value"/>

                            </label>
                        </div>
                        <div class="col-lg" v-if="showRepeat">
                            <label class="">
                                Ilość użyć
                                <input type="number"
                                       step="1"
                                       min="1"
                                       @change="emitChange"
                                       class="form-control"
                                       v-bind:name="'additional_field['+index+'][repeat]'"
                                       v-model="newField.repeat"/>

                            </label>

                        </div>
                        <div class="col-lg-auto  pt-4" v-if="!makeReadOnly">
                            <button type="button"
                                    @click="removeAdditionalField(newField)"
                                    class="btn btn-sm btn-outline-danger">Usuń
                            </button>

                        </div>

                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import {VueSelect} from "vue-select/src";

export default {
    name: 'AdditionalFields',
    components: {VueSelect},
    data: function () {
        return {
            newAdditionalFields: []
        }
    },
    props: {
        makeReadOnly: {
            type: Boolean,
        },
        hasActivation: {
            type: Boolean,
        },
        title: {
            default: 'Tymczasowe pola dodatkowe'
        },
        showRepeat: {
            default: true
        },
        additionalFields: {
            type: Array,
            required: true
        },
        fields: {
            type: Array
        }
    },
    methods: {
        myChangeEvent(newField, val) {
            newField.additional_field_id = val;
        },
        addAdditionalItem() {
            this.newAdditionalFields.push({
                // additional_field: _.head(this.additionalFields),
                // additional_field_id: _.head(this.additionalFields).id,
                value: 0,
                repeat: 1
            });
            this.emitChange();
        },
        removeAdditionalField(field) {
            this.newAdditionalFields.splice(this.newAdditionalFields.indexOf(field), 1);
            this.emitChange();
        },
        emitChange() {
            this.$emit('fieldsChanged', this.newAdditionalFields);
        },
        init() {
            this.newAdditionalFields = this.fields;
        },

    },
    beforeMount() {
        this.init();
    }
}
</script>
