<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h3>Lista aplikacji</h3>
            </div>
            <div class="card-body">
                <p>Wybierz aplikacje i miasta w których pracujesz.</p>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th width="200">Aplikacja</th>
                            <th width="70">Logo</th>
                            <th>Miasta</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="app in applications">
                            <td>{{ app.name }}
                                <input type="hidden"
                                       name="appliactionId[]"
                                       v-bind:value="app.id"/></td>
                            <td>
                                <img v-bind:src="app.logo"
                                     v-if="app.logo"
                                     class="img-fluid"
                                     v-bind:alt="app.name"/>
                            </td>
                            <td>
                                <multiselect v-model="userApplications[app.id]"
                                             :close-on-select="false"
                                             :multiple="true"
                                             :disabled="hideSave"
                                             :placeholder="'Wybierz'"
                                             :selectLabel="'Naciśnij enter aby wybrać'"
                                             :deselectLabel="'Naciśnij enter aby usunąć'"
                                             :options="getCities(app.id)"></multiselect>


                                <input type="hidden"
                                       name="appliactionCity[]"
                                       v-bind:value="getJoinedCities(userApplications[app.id])"/>
                            </td>

                        </tr>


                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer"
                 v-if="!hideSave">
                <button type="submit"
                        class="btn btn-success">Zapisz listę
                </button>
            </div>
        </div>

    </div>
</template>
<script>
import * as _ from 'lodash';
import Multiselect from 'vue-multiselect'

export default {
    name: 'ApplicationSelect',
    components: {Multiselect},
    methods: {
        getJoinedCities(cities) {
            if (_.isNil(cities)) {
                return '';

            }
            return cities.join(',');
        },
        getApp: function (id) {
            return _.find(this.applications, app => app.id === id);
        },
        getCities: function (id) {
            const app = _.find(this.applications, (app) => app.id === id);
            if (!app || !app.cities) {
                return [];
            }
            const cities = app.cities.split('\r\n');
            return cities;
        },
    },
    data: function () {
        return {
            error: '',
            userApplications: Array,
            newApplication: {
                id: null,
                city: null
            },
        }
    },
    props: {
        hideSave: {
            type: Boolean,
        },
        applications: {
            type: Array,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    beforeMount() {
        this.userApplications = [];
        _.forEach(this.user.applications, (application) => {
            this.userApplications[application.id] = application.pivot.cities === '' ? null : application.pivot.cities.split(',');
        });
    }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
