<template>
  <div class="form-group row">
    <label for="name" class="col-sm-2 col-form-label"> Wymagane Dokumenty:</label>
    <div class="col-sm-10">
      <div class="row">
        <div class="col-sm">
          Typ
        </div>
        <div class="col-sm">
          Krok
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-2">
              Firma
            </div>
            <div class="col-2">
              Obcokrajowiec
            </div>
            <div class="col-2">
              Student
            </div>
            <div class="col-2">
              Taxi
            </div>
            <div class="col-2">
              Wymagany
            </div>
          </div>
        </div>
        <div class="col-sm">
          Akcje
        </div>
      </div>
      <div v-for="(newDocument,index) in newDocuments">
        <div id="document-rows">
          <div class="row document-row">
            <div class="col-sm">
              <v-select :value="newDocument.document_type_id"
                        v-model="newDocument.document_type_id"
                        :clearable="false"
                        @input="(document) => myChangeEvent(newDocument, document)"
                        :options="documentTypes"
                        :reduce="document => document.id"
                        label="name">
              </v-select>
              <input type="hidden"
                     v-bind:name="'documents['+index+'][document_type_id]'"
                     v-bind:value="newDocument.document_type_id"
              >
            </div>
            <div class="col-sm">
              <select id="optionsSelect" v-model="newDocument.step" class="form-control">
                <option disabled value="">Wybierz jeden</option>
                <option value="7">7</option>
                <option value="10">10</option>
              </select>
              <input type="hidden"
                     v-bind:name="'documents['+index+'][step]'"
                     v-bind:value="newDocument.step"
              >
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-2">
                  <input type="checkbox"
                         class="form-control"
                         v-model="newDocument.is_firm"/>
                </div>
                <div class="col-2">
                  <input type="checkbox"
                         class="form-control"
                         v-model="newDocument.is_foreign"/>
                </div>
                <div class="col-2">
                  <input type="checkbox"
                         class="form-control"
                         v-model="newDocument.is_student"/>
                </div>
                <div class="col-2">
                  <input type="checkbox"
                         class="form-control"
                         v-model="newDocument.is_taxi"/>
                </div>
                <div class="col-2">
                  <input type="checkbox"
                         class="form-control"
                         v-model="newDocument.is_required"/>
                </div>
              </div>
            </div>
            <div class="col-sm">
              <button type="button"
                      @click="removeDocument(newDocument)"
                      class="btn btn-sm btn-outline-danger">Usuń
              </button>
              <input type="hidden"
                     v-bind:name="'documents['+index+'][is_firm]'"
                     v-bind:value="newDocument.is_firm ? 1: 0"
              >
              <input type="hidden"
                     v-bind:name="'documents['+index+'][is_foreign]'"
                     v-bind:value="newDocument.is_foreign ? 1: 0"
              >
              <input type="hidden"
                     v-bind:name="'documents['+index+'][is_student]'"
                     v-bind:value="newDocument.is_student ? 1: 0"
              >
              <input type="hidden"
                     v-bind:name="'documents['+index+'][is_taxi]'"
                     v-bind:value="newDocument.is_taxi ? 1: 0"
              >
              <input type="hidden"
                     v-bind:name="'documents['+index+'][is_required]'"
                     v-bind:value="newDocument.is_required ? 1: 0"
              >
            </div>
          </div>
        </div>
      </div>
      <button type="button" id="add-row" class="btn btn-primary mt-2" v-on:click="addNewDocument()">Nowy</button>
    </div>
  </div>
</template>

<script>


import {VueSelect} from "vue-select/src";

export default {
  name: 'RequiredDocuments',
  components: {VueSelect},
  data: function () {
    return {
      newDocuments: []
    }
  },
  props: {
    documents: {
      type: Array,
      required: true
    },
    documentTypes: {
      type: Array
    },
  },
  methods: {
    myChangeEvent(newDocument, val) {
      newDocument.document_type_id = val;
    },
    addNewDocument() {
      this.newDocuments.push({
        is_firm: false,
        is_foreign: false,
        is_student: false,
        is_taxi: false,
        is_required: false,
        document_type_id: null,
        step: 7
      });
      this.emitChange();
    },
    removeDocument(document) {
      this.newDocuments.splice(this.newDocuments.indexOf(document), 1);
      this.emitChange();
    },
    emitChange() {
      this.$emit('documentsChanged', this.newDocuments);
    },
    init() {
      this.newDocuments = this.documents;
    },

  },
  beforeMount() {
    this.init();
  }
}
</script>
