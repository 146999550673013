<template>
	<div class="form-group row">
		<div class="col-sm-2">
			Adresaci
		</div>
		<div class="col-sm-5">
			<strong>Kierowcy</strong>
			<br>
			<div class="row">
				<div class="col">
					<input v-model="search"
						   placeholder="Wyszukaj"
						   class="form-control"/>

				</div>
				<div class="col-auto">
					<button class="btn btn-outline-secondary"
							type="button"
							v-on:click="search=''">Reset
					</button>
					<button class="btn btn-outline-primary"
							type="button"
							v-on:click="addFiltered()">Dodaj wszystkich
					</button>
				</div>
			</div>
			<div class="users-list-box">
				<ul>
					<li v-for="item in getUnselectedUsers()"
						v-on:click="addSelected(item)"
						:key="item.id">
						{{ item.name}} - {{item.city}} <span class="badge"
															 v-for="app in item.applications">{{app.name}}</span>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-sm-5">
			<strong>Wybrani</strong>
			<div class="row">
				<div class="col-auto">
					<button class="btn btn-outline-secondary"
							type="button"
							v-on:click="removeAllSelected()">Usuń wszystkich
					</button>
				</div>
			</div>
			<div class="users-list-box">
				<ul>
					<li v-for="item in selectedUsersA"
						v-on:click="removeSelected(item)"
						:key="item.id">
						{{ item.name}} - {{item.city}} <span class="badge"
															 v-for="app in item.applications">{{app.name}}</span>
					</li>
				</ul>
			</div>
		</div>
		<input type="hidden"
			   name="users"
			   :value="getSelectedIds()"/>
	</div>

</template>
<script>
	export default {
		name: 'UsersSelect',

		methods: {
			addSelected: function (item) {
				this.selectedUsersA.push(item);
			},
			removeSelected: function (item) {
				this.selectedUsersA = _.remove(this.selectedUsersA, (arrayItem) => arrayItem.id !== item.id);
			},
			getUnselectedUsers: function () {
				return _.differenceWith(this.getFilteredUsers(), this.selectedUsersA, (obj1, obj2) => obj1.id === obj2.id);
			},
			addFiltered: function () {
				_.forEach(this.getUnselectedUsers(), (item) => this.addSelected(item));
			},
			getSelectedIds: function () {
				return _.map(this.selectedUsersA, (item) => item.id);
			},
			removeAllSelected: function () {
				this.selectedUsersA = [];
			},
			getFilteredUsers: function () {
				if (this.search === '') {
					return this.usersA;
				} else {
					return _.filter(this.usersA, (user) => _.some(user, v => _.includes(v, this.search)) || _.size(_.filter(user.applications, (app) => _.some(app, a => _.includes(a, this.search)))) > 0);
				}
			}
		},
		data: function () {
			return {
				search: '',
				usersA: this.users,
				selectedUsersA: this.selectedUsers
			}
		},
		props: {
			users: {
				type: Array,
				required: true
			},
			selectedUsers: {
				type: Array,
				required: true
			},
		},

	}
</script>
